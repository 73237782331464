import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Header/Header";
import Home from "./Pages/Home";
import ScrollToTop from "./Utils/scrollToTop";
import "./App.css";

import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import DeleteData from "./Pages/DeleteData";
import Support from "./Pages/Support";

const theme = {
  colors: {
    primary: "#6191d6",
    secundary: "#f1f1f1",
    navFontColor: "#dbdbdb",
    burgerFontColor: "#525151",
    burgerBGColor: "#ffffff",
    lngFontColor: "#ffffff",
    lngDropdownFontColor: "#dbdbdb",
    lngChevronColor: "#ffffff",
    lngDropdownBGColor: "#6191d6",
    inputBorderColor: "#ffffff",
    darkGray: "#525151",
    grey: "#787878",
    middlegrey: "#ededed",
    lightgrey: "#f9f9f9",
    white: "#ffffff",
    red: "#f22e2e",
    black: "#000000",
  },
  typography: {
    fontFamiliy: "Arial",
  },
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.typography.fontFamiliy};
  }
`;

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/delete-data" component={DeleteData} />
          <Route path="/support" component={Support} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
