import firebaseConfig from "./firebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

const firebaseApp = firebase.initializeApp(firebaseConfig);

initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LeI5d4jAAAAAMgcL2C2CxvzEE8A5ivu0iGxRqH1"),
  isTokenAutoRefreshEnabled: true,
});

const functions = firebaseApp.functions();

export const callCloudFunctionWithAppCheck = (functionToCall, data) => {
  return functions.httpsCallable(functionToCall)(data);
};
