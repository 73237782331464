import React from "react";
import styled from "styled-components";
import { device } from "../Components/Devices";
import NavMenu from "./NavMenu";
import BurgerMenu from "./BurgerMenu";

const HeaderContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.primary};
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px 20px;
`;

const StyledBurgerMenu = styled(BurgerMenu)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const StyledNavMenu = styled(NavMenu)`
  @media ${device.tablet} {
    display: none;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <NavContainer>
        <StyledBurgerMenu />
        <StyledNavMenu />
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;
