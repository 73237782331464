import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { device } from "../Components/Devices";
import background from "../Assets/background.png";

const Background = styled.div`
  background-image: url(${background});
  background-size: cover;
`;

const PrivacyPolicyContainer = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  width: 70%;
  margin: 0px auto;

  @media ${device.mobileL} {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90%;
  }
`;

const Content = styled.p`
  color: #ffffff;

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Background>
      <PrivacyPolicyContainer>
        <h1>{t("PrivacyPolicy.Title")}</h1>
        <Content>{t("PrivacyPolicy.Content")}</Content>
        <h2>{t("PrivacyPolicy.Title1")}</h2>
        <Content>{t("PrivacyPolicy.Content1")}</Content>
        <h2>{t("PrivacyPolicy.Title2")}</h2>
        <Content>{t("PrivacyPolicy.Content2")}</Content>
        <h2>{t("PrivacyPolicy.Title3")}</h2>
        <Content>{t("PrivacyPolicy.Content3")}</Content>
        <h2>{t("PrivacyPolicy.Title4")}</h2>
        <Content>{t("PrivacyPolicy.Content4")}</Content>
        <h2>{t("PrivacyPolicy.Title5")}</h2>
        <Content>{t("PrivacyPolicy.Content5")}</Content>
        <h2>{t("PrivacyPolicy.Title6")}</h2>
        <Content>{t("PrivacyPolicy.Content6")}</Content>
        <h2>{t("PrivacyPolicy.Title7")}</h2>
        <Content>{t("PrivacyPolicy.Content7")}</Content>
      </PrivacyPolicyContainer>
    </Background>
  );
};

export default PrivacyPolicy;
