import React from "react";
import { useTranslation } from "react-i18next";

import NavContainer from "../Components/Navigation/NavMenu/NavContainer";
import NavLink from "../Components/Navigation/NavMenu/NavLink";
import NavP from "../Components/Navigation/NavMenu/NavP";

const checkHomeActive = () => {
  const pathname = window.location.pathname;
  return pathname === "/";
};

const NavMenu = ({ className }) => {
  const { t } = useTranslation();

  return (
    <NavContainer className={className}>
      <NavLink to="/" isActive={checkHomeActive}>
        <NavP>{t("Nav.Home")}</NavP>
      </NavLink>
      <NavLink to="/privacy-policy">
        <NavP>{t("Nav.PrivacyPolicy")}</NavP>
      </NavLink>
      <NavLink to="/terms-of-service">
        <NavP>{t("Nav.TermsOfService")}</NavP>
      </NavLink>
      <NavLink to="/support">
        <NavP>{t("Nav.Support")}</NavP>
      </NavLink>
      <NavLink to="/delete-data">
        <NavP>{t("Nav.DeleteData")}</NavP>
      </NavLink>
    </NavContainer>
  );
};

export default NavMenu;
