import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import FocusLock from "react-focus-lock";

import BurgerContainer from "../Components/Navigation/BurgerMenu/BurgerContainer";
import BurgerButton from "../Components/Navigation/BurgerMenu/BurgerButton";
import BurgerLink from "../Components/Navigation/BurgerMenu/BurgerLink";
import BurgerP from "../Components/Navigation/BurgerMenu/BurgerP";

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

const BurgerMenu = ({ className }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const { t } = useTranslation();

  useOnClickOutside(node, () => setOpen(false));

  const checkHomeActive = () => {
    const pathname = window.location.pathname;
    return pathname === "/";
  };

  return (
    <div className={className}>
      <div ref={node}>
        <FocusLock disabled={!open}>
          <BurgerButton open={open} setOpen={setOpen} />
          <BurgerContainer open={open} setOpen={setOpen}>
            <BurgerLink
              to="/"
              isActive={checkHomeActive}
              onClick={() => setOpen(false)}
            >
              <BurgerP>{t("Nav.Home")}</BurgerP>
            </BurgerLink>
            <BurgerLink to="/privacy-policy" onClick={() => setOpen(false)}>
              <BurgerP>{t("Nav.PrivacyPolicy")}</BurgerP>
            </BurgerLink>
            <BurgerLink to="/terms-of-service" onClick={() => setOpen(false)}>
              <BurgerP>{t("Nav.TermsOfService")}</BurgerP>
            </BurgerLink>
            <BurgerLink to="/support" onClick={() => setOpen(false)}>
              <BurgerP>{t("Nav.Support")}</BurgerP>
            </BurgerLink>
            <BurgerLink to="/delete-data" onClick={() => setOpen(false)}>
              <BurgerP>{t("Nav.DeleteData")}</BurgerP>
            </BurgerLink>
          </BurgerContainer>
        </FocusLock>
      </div>
    </div>
  );
};

export default BurgerMenu;
