import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { device } from "../Components/Devices";
import logo from "../Assets/logo.png";
import logoAppStore from "../Assets/logo-app-store.png";
import logoPlayStore from "../Assets/logo-play-store.png";
import background from "../Assets/background.png";

const Background = styled.div`
  background-image: url(${background});
  background-size: cover;
  height: 100vh;
`;

const HomeContainer = styled.div`
  width: 70%;
  max-width: 650px;
  margin: 0px auto;
  padding-top: 120px;

  @media ${device.mobileL} {
    padding-top: 50px;
    width: 90%;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  color: #ffffff;

  @media ${device.mobileL} {
    font-size: 24px;
    padding-bottom: 10px;
  }
`;

const Logo = styled.img`
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;

  @media ${device.mobileL} {
    height: 100px;
    margin-bottom: 30px;
  }
`;

const Text = styled.p`
  text-align: left;
  color: #ffffff;
  font-size: 20px;
  margin: 5px 0px;
  font-weight: 300;

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const Ul = styled.ul`
  @media ${device.mobileL} {
    margin-right: 10px;
  }
`;

const LiEl = styled.li`
  color: #ffffff;
`;

const LogoContainer = styled.div`
  height: 50px;
  display: block;
  text-align: center;

  @media ${device.mobileL} {
    margin-top: 20px;
    height: 30px;
  }
`;

const StoreLogo = styled.img`
  height: 40px;
  display: inline-block;
  margin: 0px 10px;

  @media ${device.mobileL} {
    height: 30px;
  }
`;

const StoreLogoLink = styled.a``;

const Download = styled.p`
  margin-top: 80px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  color: #ffffff;

  @media ${device.mobileL} {
    font-size: 14px;
    font-weight: bold;
    margin: 60px 2px 0px 2px;
  }
`;

const Home = () => {
  const { t } = useTranslation();

  return (
    <Background>
      <HomeContainer>
        <Title>{t("Home.Title")}</Title>
        <Logo src={logo} />
        <Ul>
          <LiEl>
            <Text>{t("Home.Subtitle1")}</Text>
          </LiEl>
          <LiEl>
            <Text>{t("Home.Subtitle2")}</Text>
          </LiEl>
          <LiEl>
            <Text>{t("Home.Subtitle3")}</Text>
          </LiEl>
        </Ul>
        <Download>{t("Home.Download")}</Download>
        <LogoContainer>
          <StoreLogoLink href="https://play.google.com/store/apps/details?id=com.nelinik.TheQuizBattleApp">
            <StoreLogo src={logoPlayStore} />
          </StoreLogoLink>
          <StoreLogoLink href="https://apps.apple.com/ch/app/the-quiz-battle/id1458088119">
            <StoreLogo src={logoAppStore} />
          </StoreLogoLink>
        </LogoContainer>
      </HomeContainer>
    </Background>
  );
};

export default Home;
