const firebaseConfig = {
  apiKey: "AIzaSyC4cZuv1sLMn6Ubo8-qZeBecwrYrxqqF1I",
  authDomain: "thequizbattlewebsite.firebaseapp.com",
  projectId: "thequizbattlewebsite",
  storageBucket: "thequizbattlewebsite.appspot.com",
  messagingSenderId: "425219573281",
  appId: "1:425219573281:web:e5f976ebc338a57968c560",
  measurementId: "G-BNT50VV956",
};

export default firebaseConfig;
