import styled from "styled-components";
import SupportForm from "../Components/Form/SupportForm";
import FormInput from "../Components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { device } from "../Components/Devices";
import background from "../Assets/background.png";

const Background = styled.div`
  background-image: url(${background});
  background-size: cover;
  height: 100vh;
`;

const SupportContainer = styled.div`
  padding-top: 120px;
  width: 70%;
  margin: 0px auto;

  @media ${device.mobileL} {
    padding-top: 50px;
    width: 90%;
  }
`;

const Title = styled.h1`
  @media ${device.mobileL} {
    padding-bottom: 0px;
  }
`;

const Support = () => {
  const { t } = useTranslation();

  const sendButtonText = <p>{t("Support.SendNow")}</p>;

  const successMessage = (
    <>
      <p>{t("Support.SuccessMessage1")}</p>
      <p>{t("Support.SuccessMessage2")}</p>
    </>
  );

  const errorMessage = (
    <>
      <p>{t("Support.ErrorMessage1")}</p>
      <p>{t("Support.ErrorMessage2")}</p>
    </>
  );

  return (
    <Background>
      <SupportContainer>
        <Title>{t("Support.Title")}</Title>
        <SupportForm
          sendButtonText={sendButtonText}
          successMessage={successMessage}
          errorMessage={errorMessage}
        >
          <FormInput
            placeholder={t("Support.FirstName")}
            type="text"
            name="firstName"
            marginTop="80px"
            marginBottom="20px"
          />
          <FormInput
            placeholder={t("Support.LastName")}
            type="text"
            name="lastName"
            marginBottom="20px"
          />
          <FormInput
            placeholder={t("Support.Email")}
            type="email"
            name="email"
            marginBottom="20px"
          />
          <FormInput
            placeholder={t("Support.Message")}
            name="message"
            type="textarea"
            marginBottom="45px"
            multiline
          />
        </SupportForm>
      </SupportContainer>
    </Background>
  );
};

export default Support;
