import styled from "styled-components";
import DeleteForm from "../Components/Form/DeleteForm";
import FormInput from "../Components/Form/FormInput";
import { useTranslation } from "react-i18next";
import { device } from "../Components/Devices";
import background from "../Assets/background.png";

const Background = styled.div`
  background-image: url(${background});
  background-size: cover;
  height: 100vh;
`;

const DeleteDataContainer = styled.div`
  padding-top: 120px;
  width: 70%;
  margin: 0px auto;

  @media ${device.mobileL} {
    padding-top: 50px;
    width: 90%;
  }
`;

const Title = styled.h1`
  @media ${device.mobileL} {
    padding-bottom: 0px;
  }
`;

const DeleteData = () => {
  const { t } = useTranslation();
  const sendButtonText = <p>{t("DeleteData.SendNow")}</p>;

  const successMessage = (
    <>
      <p>{t("DeleteData.SuccessMessage1")}</p>
      <p>{t("DeleteData.SuccessMessage2")}</p>
    </>
  );

  const errorMessage = (
    <>
      <p>{t("DeleteData.ErrorMessage1")}</p>
      <p>{t("DeleteData.ErrorMessage2")}</p>
    </>
  );

  return (
    <Background>
      <DeleteDataContainer>
        <Title>{t("DeleteData.Title")}</Title>
        <DeleteForm
          sendButtonText={sendButtonText}
          successMessage={successMessage}
          errorMessage={errorMessage}
        >
          <FormInput
            placeholder={t("DeleteData.Username")}
            type="text"
            name="username"
            marginTop="80px"
            marginBottom="20px"
            width="90%"
          />
          <FormInput
            placeholder={t("DeleteData.Message")}
            name="message"
            type="textarea"
            marginBottom="45px"
            width="90%"
            multiline
          />
        </DeleteForm>
      </DeleteDataContainer>
    </Background>
  );
};

export default DeleteData;
